.block-list {
    display: flex;
}

.level-grid.table {
    border: none;
    td {
        border: none;
        text-align: center;
        vertical-align: middle;
    }
}

.game-tile {
    width: 64px;
    height: 64px;
    background-image: url("../components/Game/img/tilesheet.png"), url("../components/Game/img/tilesheet.png");
}

.game-tile-0 { background-position: 0 0, -64px -192px}
.game-tile-1 { background-position: -576px 0, -64px -192px}
.game-tile-2 { background-position: -128px 0, -64px -192px}
.game-tile-3 { background-position: 0 -256px, -64px -192px;}
.game-tile-4 { background-position: -192px -192px, -64px -192px;}
.game-tile-10 { background-position: 0px -128px, -64px -192px}
.game-tile-11 { background-position: -64px -128px, -64px -192px}
.game-tile-12 { background-position: -128px -128px, -64px -192px;}
.game-tile-13 { background-position: -192px -128px, -64px -192px;}
.game-tile-20 { background-position: 0px -64px, -64px -192px;}
.game-tile-21 { background-position: -64px -64px, -64px -192px;}
.game-tile-22 { background-position: -128px -64px, -64px -192px;}
.game-tile-23 { background-position: -192px -64px, -64px -192px;}
.game-tile-30 { background-position: -256px -128px, -64px -192px;}
