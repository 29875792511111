.navbar {
    border-bottom: 2px solid #eee;
}


ul.level-list {
    margin: 0;
}

li.level-list-item {
    list-style: none;

    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;


    &--unpublished {

        a {
            color: #999
        }
    }
}

.level-list-item__name {
    padding: .75rem 1rem;
    flex: 2;
}

.level-list-item__author {
    padding: .75rem 1rem;
    flex: 1;
}

.level-list-item__completion {
    flex: 1;
    text-align: right;
    background-color: #f5f5f5;
    max-width: 3rem;
    .fa-check {
        color: #ccc;
    }
    &--started {
        background-color: #ee7700;
        .fa-check {
            color: #fff;
        }
    }
    &--completed {
        background-color: #88dd88;
        .fa-check {
            color: #fff;
        }
    }
}
